.Collapsible {
  background-color: #21252b; }

.Collapsible__contentInner {
  padding: 10px;
  border: 1px solid black;
  border-top: 0; }
  .Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px; }
    .Collapsible__contentInner p:last-child {
      margin-bottom: 0; }

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: #333333;
  position: relative;
  border: 1px solid black;
  padding: 10px;
  background: #323842cf;
  color: white; }
  .Collapsible__trigger:after {
    font-family: 'Font Awesome\ 5 Free';
    content: '\f107';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms;
    font-weight: 900;
    font-size: x-large;
   }
  .Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg); }

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease; }

.CustomTriggerCSS--open {
  background-color: darkslateblue; }

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 16px;
  background-color: #CBB700;
  color: black; }