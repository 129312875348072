.microphone {
    color: lightgrey !important;
    width: 35px;
    height: 35px;
    position: relative;
    border-radius: 100% !important;
}

.btn:focus {
    outline: none !important;
    outline-offset: none !important;
    box-shadow: none !important;
}

.listening {
    color: red !important;
}


.icon {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    display: block;
}